import axios from "axios";
import { ApiResponse } from "@/types/api";
import { Lead } from "@/types/broadbandLeadContext";
import { handleApiError } from "@/utils/formatApiError";

export const createBroadbandLead = async ({
	...params
}): Promise<ApiResponse<Lead | null>> => {
	try {
		const response = await axios({
			url: `/api/leads`,
			method: "POST",
			data: {
				industry: process.env.NEXT_PUBLIC_BROADBAND_INDUSTRY,
				additionalFields: {
					house_number:
						params.building_number ??
						params.building_name ??
						params.sub_building_name ??
						params.sub_building_number,
					address_line_one: params.line_1,
					address_line_two: params.line_2,
					post_town: params.town_or_city,
					county: params.county,
					postcode: params.postcode,
					preferred_speeds: params.preferredSpeed,
					current_provider: params.currentProvider,
				},
				...params,
			},
		});

		return {
			data: response.data,
			error: null,
		};
	} catch (e: any) {
		return {
			data: null,
			error: handleApiError(e),
		};
	}
};

export const createMobileLead = async ({
	...params
}): Promise<ApiResponse<Lead | null>> => {
	try {
		const response = await axios({
			url: `/api/leads`,
			method: "POST",
			data: {
				industry: process.env.NEXT_PUBLIC_MOBILE_INDUSTRY,
				additionalFields: {
					type_of_deal: params.typeOfDeal,
					monthly_budget: params.monthlyBudget,
					current_provider: params.currentProvider,
					data_requirement: params.dataRequirement,
					minutes_requirement: params.minutesRequirement,
				},
				...params,
			},
		});

		return {
			data: response.data,
			error: null,
		};
	} catch (e: any) {
		return {
			data: null,
			error: handleApiError(e),
		};
	}
};

export const createHeatPumpLead = async ({
	...params
}): Promise<ApiResponse<Lead | null>> => {
	try {
		const response = await axios({
			url: `/api/leads`,
			method: "POST",
			data: {
				industry: process.env.NEXT_PUBLIC_HEAT_PUMP_INDUSTRY,
				additionalFields: {
					existing_system: params.existingSystem,
					home_or_business: params.homeOrBusiness,
					owner_or_tenant: params.ownerOrTenant,
					property_type: params.propertyType,
					type_of_insulation: params.typeOfInsulation,
					postcode: params.postcode,
				},
				...params,
			},
		});

		return {
			data: response.data,
			error: null,
		};
	} catch (e: any) {
		return {
			data: null,
			error: handleApiError(e),
		};
	}
};

export const createSolarLead = async ({
	...params
}): Promise<ApiResponse<Lead | null>> => {
	try {
		const response = await axios({
			url: `/api/leads`,
			method: "POST",
			data: {
				industry: process.env.NEXT_PUBLIC_SOLAR_INDUSTRY,
				additionalFields: {
					house_number:
						params.address.building_number ??
						params.address.building_name ??
						params.address.sub_building_name ??
						params.address.sub_building_number,
					address_line_one: params.address.line_1,
					address_line_two: params.address.line_2,
					post_town: params.address.town_or_city,
					county: params.address.county,
					postcode: params.postcode,
					solar_solution: params.solarSolution,
					type_of_work: params.typeOfWork,
					property_type: params.propertyType,
				},
				...params,
			},
		});

		return {
			data: response.data,
			error: null,
		};
	} catch (e: any) {
		return {
			data: null,
			error: handleApiError(e),
		};
	}
};

export const createEnergyLead = async ({ ...params }): Promise<any> => {
	try {
		const response = await axios({
			url: `/api/leads`,
			method: "POST",
			data: {
				industry: process.env.NEXT_PUBLIC_ENERGY_INDUSTRY,
				additionalFields: {
					quote_type: params.quoteType,
					current_provider: params.currentProvider,
					type_of_energy: params.typeOfEnergy,
					payment_method: params.paymentMethod,
					reason: params.reason,
					homeowner: params.homeowner,
					postcode: params.postcode,
				},
				...params,
			},
		});

		return {
			data: response.data,
			error: null,
		};
	} catch (e: any) {
		return {
			data: null,
			error: handleApiError(e),
		};
	}
};
