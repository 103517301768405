import * as React from "react";
import Image from "next/image";
import Heading from "../Heading";
import styles from "./styles.module.css";

const Card: React.FC<{
	iconVariant: number;
	card: {
		fields: {
			title: string;
			description: string;
		};
	};
}> = ({ card, iconVariant }) => {
	let variant;

	switch (iconVariant) {
		case 1:
			variant = {
				color: "blue",
				src: "/icons/rocket.svg",
			};
			break;
		case 2:
			variant = {
				color: "teal",
				src: "/icons/calendar.svg",
			};
			break;
		case 3:
			variant = {
				color: "purple",
				src: "/icons/money.svg",
			};
			break;
		case 4:
			variant = {
				color: "red",
				src: "/icons/settings.svg",
			};
			break;
		default:
			variant = {
				color: "blue",
				src: "/icons/rocket.svg",
			};
	}

	return (
		<div
			className={`${styles.panel} ${styles[`panel--${variant.color}`]} h-100 `}
		>
			<div className={`${styles.panel__icon} mb-4`}>
				<Image src={variant.src} alt="sky" width="32" height="32" />
			</div>
			<Heading variant={3} isBold={false} title={card.fields.title} />
			<p className={styles.panel__text}>{card.fields.description}</p>
		</div>
	);
};

export default Card;
