import Card from "../Card";
import * as React from "react";
import { TypeMainUsp } from "@/types/index";

export const CardList: React.FC<{
	list: Array<TypeMainUsp | undefined>;
}> = ({ list }) => {
	const cardList = list.map((usp, index) => {
		if (!usp) return null;

		return (
			<div className="col-12 col-md-6 mb-4" key={index}>
				<Card iconVariant={index + 1} card={usp} />
			</div>
		);
	});

	return <>{cardList}</>;
};

export default CardList;
