import Pill from "../Pill";
import * as React from "react";

export const PillList: React.FC<{
	items: Array<string>;
}> = ({ items }) => {
	let pillList = items.map(function (value, index) {
		return (
			<span key={index}>
				<Pill name={value} />
			</span>
		);
	});

	return <>{pillList}</>;
};

export default PillList;
