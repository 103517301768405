import Link from "next/link";
import * as React from "react";
import styles from "./styles.module.css";

export type FooterItem = {
  title: string;
  href: string;
};

export type FooterSubMenu = {
  [key: string]: FooterItem[];
};

export type FooterType = FooterSubMenu[];

const links: FooterType = [
  {
    Broadband: [
      {
        title: "Broadband Deals",
        href: "/broadband",
      },
      {
        title: "Broadband and Home Phone",
        href: "/broadband/broadband-and-home-phone",
      },
      {
        title: "Broadband and TV",
        href: "/broadband/tv",
      },
      {
        title: "Fibre Broadband",
        href: "/broadband/fibre-broadband",
      },
    ],
  },
  {
    Mobile: [
      {
        title: "Contract Phones",
        href: "/mobile",
      },
      {
        title: "SIM Only Deals",
        href: "/mobile/sim-only",
      },
      {
        title: "iPhone Deals",
        href: "/mobile/apple/iphone",
      },
      {
        title: "Samsung Deals",
        href: "/mobile/samsung",
      },
      {
        title: "iPhone 14 Deals",
        href: "/mobile/apple/iphone/14-5g-dual-sim",
      },
      {
        title: "iPhone 14 Pro Deals",
        href: "/mobile/apple/iphone/14-pro-5g-dual-sim",
      },
      {
        title: "Samsung Galaxy S22 Deals",
        href: "/mobile/samsung/galaxy/s22-5g",
      },
      {
        title: "Samsung Galaxy S23 Deals",
        href: "/mobile/samsung/galaxy/s23-5g-dual-sim",
      },
    ],
  },
  // {
  //   Energy: [
  //     {
  //       title: "Compare Gas and Electricity",
  //       href: "/energy/compare-gas-and-electricity",
  //     },
  //     {
  //       title: "Gas and Electricity Guides",
  //       href: "/energy/guides",
  //     },
  //     {
  //       title: "Energy News",
  //       href: "/energy/news",
  //     },
  //   ],
  // },
  {
    Insurance: [
      {
        title: "Car Insurance",
        href: "/insurance/car-insurance",
      },
      {
        title: "Home Insurance",
        href: "/insurance/home-insurance",
      },
      {
        title: "Van Insurance",
        href: "/insurance/van-insurance",
      },
      {
        title: "Bicycle Insurance",
        href: "/insurance/bicycle-insurance",
      },
      {
        title: "Pet Insurance",
        href: "/insurance/pet-insurance",
      },
      {
        title: "Travel Insurance",
        href: "/insurance/travel-insurance",
      },
    ],
  },
  {
    About: [
      {
        title: "About Us",
        href: "/about-us",
      },
      {
        title: "Contact Us",
        href: "/contact-us",
      },
      // {
      //   title: "Complaints",
      //   href: "/legal/complaints",
      // },
      {
        title: "Privacy Policy",
        href: "/legal/privacy-policy",
      },
      {
        title: "Cookie Policy",
        href: "/legal/cookie-policy",
      },
      {
        title: "Terms and Conditions",
        href: "/legal/terms-of-service",
      },
    ],
  },
];

const Footer: React.FC = () => {
  const listItems = links.map((menu: FooterSubMenu) => {
    for (const [key, value] of Object.entries(menu)) {
      const rows = value.map((item: FooterItem) => {
        return (
          <li key={item.title}>
            <Link href={item.href} legacyBehavior>
              <a>{item.title}</a>
            </Link>
          </li>
        );
      });

      return (
        <div className="col-lg col-sm-3 col-6 mt-4 mt-sm-0" key={key}>
          <div className={styles.list__container}>
            <div className={styles.links__header}>{key}</div>
            <ul>{rows}</ul>
          </div>
        </div>
      );
    }
  });

  return (
    <footer className={`${styles.footer} py-3`}>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className={styles.footer__logo}>Savely.</div>
          </div>
        </div>
        <div className="row mt-4">
          <div className={styles.hr}></div>
        </div>
        <div className="row mt-sm-4">
          {listItems}
          <div className="col-lg col-12 mt-4 mt-lg-0">
            <div className="row">
              <div className="col-2 col-md-4 d-block d-lg-none"></div>
              <div className="col-md-4 col-8 col-lg">
                <div className="row gx-3">
                  <div className="col text-center">
                    <a
                      href="https://www.facebook.com/Savely.co.uk/"
                      target="_blank"
                      rel="noreferrer"
                      className={styles.social}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        fill="currentColor"
                        className="bi bi-facebook"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                      </svg>
                    </a>
                  </div>
                  <div className="col text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="currentColor"
                      className="bi bi-twitter"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                    </svg>
                  </div>
                  <div className="col text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="currentColor"
                      className="bi bi-linkedin"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                    </svg>
                  </div>
                  <div className="col text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="currentColor"
                      className="bi bi-youtube"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="col-2 col-md-4 d-block d-lg-none"></div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className={styles.hr}></div>
        </div>
        <div className="row mt-4">
          <div className="col text-center">
            <p className={styles.legal__text}>
              &copy; Savely Financial Services Limited. Broadband, Energy and
              Mobile comparison service.
            </p>
            <p className={styles.legal__text}>
              Savely Financial Services Limited (14649692) part of JLO Group
              Limited (13739777). Charlotte House, 500 Charlotte Road, Sheffield
              S2 4ER.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
